export default {
  Organizador: 'Organizador',
  Paciente: 'Paciente',
  Profissional: 'Profesional',
  Convidado: 'Visitante',
  Atendimentos: 'Servicios',
  Administrador: 'Administrador',
  'Documentos clínicos': 'Documentos clínicos',
  'Agenda de atendimentos': 'Cronograma de servicio',
  'Perfil do usuário': 'Perfil del usuario',
  'Detalhes do atendimento': 'Detalles del servicio',
  'Documento clínico': 'Documento clínico',
  'Pacientes cadastrados': 'Pacientes registrados',
  'Profissionais cadastrados': 'Profesionales registrados',
  'Organizadores cadastrados': 'Organizadores registrados',
  'Agendar novo atendimento': 'Programar nuevo servicio',
  'Cadastrar novo documento clínico': 'Registrar nuevo documento clínico',
  'Cadastrar novo participante': 'Registrar nuevo participante',
  'Cadastrar novo profissional': 'Registrar nuevo profesional',
  'Cadastrar novo organizador': 'Registrar nuevo organizador',
  Cancelar: 'Cancelar',
  Voltar: 'Volver',
  Próximo: 'Siguiente',
  Enviar: 'Enviar',
  'Enviar e concluir': 'Enviar y completar',
  'Sim, cancelar': 'Si, cancelar',
  'Não cancelar': 'No cancele',
  'Excluir usuário': 'Borrar usuario',
  'Novo paciente': 'Nuevo paciente',
  'Novo profissional': 'Nuevo profesional',
  'Novo organizador': 'Nuevo organizador',
  'Novo documento': 'Nuevo documento',
  'Novo agendamento': 'Nuevo servicio',
  Vencimento: 'Fecha de vencimiento',
  Disponível: 'Disponible',
  Cancelado: 'Cancelado',
  Finalizado: 'Terminado',
  Agendado: 'Programado',
  'Em andamento': 'En proceso',
  'Em aberto': 'En abierto',
  Confirmado: 'Confirmado',
  'Documento não enviado': 'Documento no enviado al paciente',
  'Dados perfil': 'Datos de perfil',
  'Mudar senha': 'Cambiar contraseña',
  Permissão: 'Permiso',
  'Excluir conta': 'Borrar cuenta',
  Sair: 'Salir',
  'Aviso importante': 'Advertencia importante',
  'Ao excluir um usuário, as informações pertinentes, como dados de atendimentos, documentos clínicos e de apoio, assim como as conversas em chat serão mantidas de acordo com as politicas de privacidade e termos de uso da plataforma. ':
    'Después de borrar un usuario, las informaciónes pertinentes de la ellos como datos de servicio, documentos clínicos y de apoyo como también las conversaciones en chat, se mantendrá de acuerdo con las políticas de privacidad y terminos de uso de la plataforma V4H servicio. ',
  'Dados profissionais': 'Datos profesionales',
  Profissão: 'Profesión',
  Salvando: 'Guardando...',
  'Salvar alterações': 'Guardar ediciones',
  'Nova senha': 'Nueva contraseña',
  'Digite nova senha': 'Escribelo nueva contraseña',
  'Senha atual': 'Contraseña atual',
  'Repita nova senha': 'Repita nueva contraseña',
  'Digite novamente a nova senha': 'Escribelo de nuevo de la nueva contraseña',
  'Dados do perfil': 'Datos del perfil',
  'Dados gerais': 'Datos generales',
  'Enviar documento por e-mail': 'Enviar documento por correo electrónico',
  'Dados Pessoais': 'Datos Personales',
  'Dados Profissionais': 'Datos Profesionales',
  'Selecione sua profissão': 'Seleccione tu profesion',
  Médico: 'Médico',
  'Digite apenas letras maiusculas e minúsculas e espaço.':
    'Escribelo solamente letras mayúsculas y minúsculas y espacio.',
  Dentista: 'Dentista',
  Contatos: 'Contactos',
  Status: 'Estado',
  Ações: 'Acciones',
  Período: 'Período',
  Tipo: 'Tipo',
  'Documentos de apoio': 'Documentos de apoyo',
  Atendimento: 'Servicio',
  Retorno: 'Regreso',
  Emergency: 'Emergencia',
  Gravado: 'Grabado',
  'Cancelar atendimento?': '¿Cancelar servicio?',
  'Deseja realmente cancelar o atendimento: ':
    'Desea realmente cancelar el servicio: ',
  'Nome completo': 'Nombre completo',
  'Seu primeiro nome': 'Tu primero nombre',
  'Seu segundo nome': 'Tu segundo nombre',
  'Digite seu nome': 'Escribelo tu nombre',
  'Digite seu sobrenome': 'Escribelo tu apellido',
  'Digite o nome do convidado': 'Escribelo el nombre del visitante',
  Gênero: 'Género',
  Masculino: 'Masculino',
  Feminino: 'Femenino',
  Cpf: 'Cpf',
  Rg: 'Rg',
  'Nome da mãe': 'Nombre de la madre',
  Especialidade: 'Especialidad',
  'Digite sua especialidade': 'Ingresa tu especialidad',
  'Data do Atendimento': 'Data del Servicio',
  'Selecione uma data disponível': 'Seleccione una fecha disponible',
  'Hora início': 'Hora início',
  'Hora fim': 'Hora final',
  Selecione: 'Seleccione',
  'Tipo de Atendimento': 'Tipo de Servicio',
  Convidados: 'Visitantes',
  'Digite o(s) email(s) para convidar':
    'Escribelo o(s) correo eletronico(s) para invitar',
  'Um Link estará disponível após a confirmação do agendamento.':
    'Un Link estará disponible despues de la confirmación del servicio.',
  'Data de nascimento': 'Fecha de nacimiento',
  Endereço: 'Dirección',
  Cep: 'Cep',
  Número: 'Número',
  Complemento: 'Complemento',
  Bairro: 'Distrito',
  UF: 'Estado',
  State: 'Estado',
  Cidade: 'Ciudad',
  Contato: 'Contacto',
  'E-mail': 'Correo electrónico',
  Senha: 'Contraseña',
  'Telefone celular': 'Teléfono celular',
  'Telefone alternativo': 'Teléfono alternativo',
  'Telefone celular 1': 'Teléfono celular 1',
  'Telefone celular 2': 'Teléfono celular 2',
  Salvar: 'Guardar',
  'Digite o seu email': 'Escribelo tu correo electrónico',
  'Digite sua Senha': 'Escribelo tu contraseña',
  Código: 'Código',
  'Digite o código do atendimento': 'Escribelo el código del servicio',
  'Sua relação familiar com o paciente': 'Tu relación familiar con el paciente',
  'Selecione uma opção': 'Seleccione una opción',
  Anterior: 'Anterior',
  'Termos de uso': 'Terminos de uso',
  'Política de privacidade': 'Política de privacidad',
  Assinado: 'Firmado',
  Pendente: 'Pendiente',
  'Lista de Documentos clínicos': 'Lista de Documentos clínicos',
  'Lista de Documentos de apoio': 'Lista de Documentos de apoyo',
  Tamanho: 'Tamaño',
  Data: 'Fecha',
  Cod: 'Cod',
  Assinatura: 'Assinatura',
  Hora: 'Hora',
  Origem: 'Origen',
  CPF: 'CPF',
  'Profissional da saúde': 'Profesional de la salud',
  'Registro de conselho': 'Registro del consejo',
  'Data de inclusão': 'Fecha de inclusión',
  Idade: 'Edad',
  Início: 'Início',
  Fim: 'Fim',
  'Iniciar atendimento': 'Comienza el servicio',
  Detalhes: 'Detalles',
  'Acessas farmácia': 'Acceso a la farmacia',
  'Ver perfil': 'Ver perfil',
  'Editar setor': 'Editar sector',
  'Baixar documento': 'Descargar documento',
  'Ir para sala de espera': 'Ir a la sala de espera',
  'Compartilhar por email': 'Compartir por correo electrónico',
  'Novo documento clínico': 'Nuevo documento clínico',
  'Não foi possível obter informações do documento clínico, tente novamente':
    'No se pudo obtener información del documento clínico, inténtelo de nuevo',
  Painel: 'Tablero',
  'Excluir usuario': 'Borrar usuario',
  'Cancelar atendimento': 'Cancelar servicio',
  'Finalizar atendimento': 'Cerrar servicio',
  'Editar especialidade': 'Editar especialidad',
  'Excluir especialidade': 'Borrar especialidad',
  'Copiar link de atendimento': 'Copiar enlace de servicio',
  'Reenviar email': 'Reenviar email',
  'Enviar convite por Whatsapp': 'Enviar invitación por Whatsapp',
  'Enviar convite': 'Enviar invitación',
  'Convites Enviados': 'Invitaciones enviadas',
  'Convidar por Link': 'Invitar por enlace',
  'Excluir documento': 'Eliminar documento',
  Avulso: 'Avulso',
  Simples: 'Sencillo',
  'Atestado Médico': 'Certificado Médico',
  'Requisição de exame': 'Solicitud de examen',
  'Status do atendimento': 'Estado del servicio',
  'Participantes da Consulta': 'Participantes de la Consulta',
  'Dados do paciente': 'Datos del paciente',
  Nome: 'Nombre',
  anos: 'anos',
  'Pronto para participar?': '¿Pronto para participar?',
  'Ações do atendimento': 'Acciones del servicio',
  'Para reagendar ou alterar dados do atendimento, você precisa editar o atendimento nos campos ao lado.':
    'Para reprogramar o cambiar datos del servicio, usted necesitas editar el servicio en los campos al lado.',
  'Atendimento cancelado': 'Servicio cancelado',
  'Dados do profissional de saúde': 'Datos del profesional de la salud',
  'Registro no conselho profissional': 'Registro en consejo profesional',
  'Data e Hora': 'Fecha y Hora',
  Profissionais: 'Profesionales',
  Concluir: 'Concluir',
  'Paciente em questão': 'Paciente en cuestión',
  'Selecione um paciente': 'Seleccione un paciente',
  'Convidar paciente para participar': 'Invitar al paciente para participar',
  'Selecione uma especialidade': 'Seleccione una especialidade',
  'Data do atendimento': 'Data del servicio',
  'Hora de início': 'Hora de início',
  'Hora do fim': 'Hora del final',
  'Selecione um profissional': 'Seleccione un profesional',
  'Convidar profissional externo': 'Invitar el profesional externo',
  'Nome do Profissional': 'Nombre del Profesional',
  'Digite o e-mail do profissional':
    'Escribelo el correo electrónico del profesional',
  'E-mail do profissional': 'Correo electrónico del profesional',
  Add: 'Add',
  'Lista de profissionais participantes':
    'Lista de profesionales participantes',
  'Digite seu CEP': 'Escribelo tu CEP',
  'Digite seu estado': 'Escribelo tu estado',
  Estado: 'Estado',
  'Digite sua cidade': 'Escribelo tu ciudad',
  'Digite seu país': 'Escribelo tu país',
  País: 'País',
  'Digite seu bairro': 'Escribelo tu bairro',
  'Rua Av. Ladeira...': 'Calle Av. Ladeira...',
  'Número da residência': 'Número de la residencia',
  'Digite um complemento': 'Escribelo un complemento',
  'Seu nome completo': 'Tu nombre completo',
  'Nome Completo': 'Nombre Completo',
  'Cadastro de Pessoa Física': 'Registro de Persona Fisica',
  'Número da identidade': 'Número de la identidad',
  'Selecione seu Gênero': 'Seleccione tu Género',
  Outros: 'Otros',
  'DD/MM/AAAA': 'DD/MM/AAAA',
  'Data de Nascimento': 'Fecha de Nacimiento',
  'Nome da sua mãe': 'Nombre de tu madre',
  'Digite uma senha pessoal': 'Escribelo una contraseña pessoal',
  'Senha de Acesso': 'Contraseña de Acceso',
  'Confirme a senha': 'Confirme de la contraseña',
  'Repita a senha de acesso': 'Repita de la contraseña de acceso',
  'Insira o Código de atendimento que você recebeu por e-mail.':
    'Ingrese el código de servicio que recibió por correo electrónico.',
  'Pai/Mãe': 'Padre/Mamá',
  'Irmã(o)': 'Hermano(a)',
  'Filho(a)': 'Hijo(a)',
  'Esposa(o)': 'Marido/Esposa',
  'Avô/Avó': 'Abuelo(a)',
  'Amigo(a)': 'Amigo',
  'Outro parente': 'Otro pariente',
  'Não listado': 'No enlistado',
  Ativo: 'Activo',
  Fraca: 'Debil',
  Médio: 'Mediana',
  Forte: 'Fuerte',
  'Senha de': 'Contraseña de',
  segurança: 'segurança',
  Avançar: 'Avance',
  Cadastro: 'Registro',
  'Selecione um papel': 'Seleccione un papel',
  'Permissões concedidas': 'Permisos concedido',
  Descrição: 'Descripción',
  Franquia: 'Franquicia',
  Papel: 'Papel',
  '+Adicionar': '+Agregar',
  Adicionar: 'Agregar',
  'Profissional de saúde': 'Profesional de salud',
  'Última edição': 'Última edición',
  'Profissionais participantes': 'Profesionales participantes',
  'Não foi possível carregar o arquivo': 'No puede cargar el arquivo',
  'Dados Gerais': 'Datos Generales',
  'Acesso à Receita': 'Acceso à Prescripción',
  Acessar: 'Acessar',
  'Digite o código da receita': 'Escribelo el código de la prescripción',
  'Digite a senha da receita': 'Escribelo la contraseña de la prescripción',
  'Insira o CÓDIGO e SENHA disponíveis na receita do paciente':
    'Insira el CÓDIGEl y CONTRASEÑA disponible en la prescripción del paciente',
  'para visualizar o arquivo assinado pelo médico':
    'para ver el arquivo firmado por el médico',
  'Criado em': 'Creado en',
  'Acesso para farmácia': 'Acceso para farmacia',
  'Receita Especial': 'Prescripción Especial',
  'Validador ICP Brasil': 'Validador ICP Brasil',
  'Baixar PDF': 'Descargar PDF',
  'Um link estará disponível após confirmação do agendamento':
    'Un link estará disponible despues de confirmación del servicio',
  'Digite o(s) e-mail(s) para convidar':
    'Escribelo o(s) correo electrónico(s) para invitar',
  'Selecione um tipo': 'Seleccione un tipo',
  'Tipo de serviço': 'Tipo de servicio',
  Mês: 'Mes',
  Hoje: 'Hoy',
  Domingo: 'Domingo',
  'Segunda-Feira': 'Lunes',
  'Terça-Feira': 'Martes',
  'Quarta-Feira': 'Miércoles',
  'Quinta-Feira': 'Jueves',
  'Sexta-Feira': 'Viernes',
  Sábado: 'Sábado',
  ' de Janeiro': ' de Enero',
  ' de Fevereiro': ' de Febrero',
  ' de Março': ' de Marzo',
  ' de Maio': ' de Mayo',
  ' de Abril': ' de Abril',
  ' de Junho': ' de Junio',
  ' de Julho': ' de Julio',
  ' de Agosto': ' de Agosto',
  ' de Setembro': ' de Septiembre',
  ' de Outubro': ' de Octubre',
  ' de Novembro': ' de Noviembre',
  ' de Dezembro': ' de Diciembre',
  'Receita simples': 'Prescripción simples',
  'Solicite a farmácia que acesse o endereço':
    'Solicite de la farmacia que acesse el endereço',
  'para baixar sua receita digital.': 'para baixar tu prescripción digital.',
  'Utilize o código e senha para acessar':
    'Utilize el código y contraseña para acessar',
  'Código:': 'Código:',
  'Senha:': 'Contraseña:',
  'Ver QR Code': 'Ver QR Code',
  'Código e Senha': 'Código y Contraseña',
  'Acesse o documento': 'Acesse el documento',
  'via o QR code do documento': 'via el QR code del documento',
  Perfil: 'Perfil',
  Pacientes: 'Pacientes',
  'Profissionais de saúde': 'Profesionales de salud',
  Organizações: 'Organizaciones',
  Painéis: 'Painéis',
  'Log de atividade': 'Log de actividad',
  'Serviços e faturamentos': 'Servicios y facturación',
  'Faturas e pagamentos': 'Facturas y Pagos',
  'Itens da fatura': 'Artículos de factura',
  'Ocultar todos os detalhes': 'Ocultar todos los detalles',
  'Status de pagamento': 'Estado de pago',
  Setores: 'Setores',
  Agenda: 'Agenda',
  Usuários: 'Usuarios',
  Administração: 'Administración',
  Serviços: 'Servicios',
  '(Pacote On Demand)': '(Paquete On Demand)',
  'Selecione um mês': 'Selecciona un mes',
  'Limpar todas': 'Limpiar todos',
  'Ocultar notificações': 'Esconder notificaciones',
  'criou um atendimento': 'creado un servicio',
  'cancelou um atendimento': 'canceló un servicio',
  'confirmou o atendimento': 'confirmó el servicio',
  'atualizou um atendimento': 'actualizado un servicio',
  'agendou o atendimento': 'programado el servicio',
  'deletou um atendimento': 'eliminado un servicio',
  'criou um atendimento com profissional acompanhante':
    'creado un servicio con profesional acompañante',
  'entrou na sala de espera': 'entró en la sala de espera',
  'saiu da sala de espera': 'salió de la sala de espera',
  'Nova mensagem': 'Nueva mensaje',
  'Menu de Serviços': 'Menú de servicios',
  'Mês de competência': 'Mes de competencia',
  'Backup em nuvem': 'Respaldo en la nube',
  'Reconhecimento facial': 'Reconocimiento facial',
  'Este recurso não está disponível no seu pacote de serviços atual.':
    'Esta función no está disponible en su paquete de servicio actual.',
  'desde o último mês': 'desde el mes pasado',
  'Atendimentos realizados': 'Servicios desempeñados',
  'Reconhecimentos realizados': 'Reconocimientos realizados',
  'Prescrições geradas': 'Prescripciones generadas',
  'Atendimentos preservados': 'Servicios preservados',
  'O valor total faturado será exibido depois que a fatura completa for fechada.':
    'El monto total facturado se mostrará después de que se cierre la factura completa.',
  'Total estimado': 'Total estimado',
  'Valor unitário': 'Valor unitario',
  'Valor total': 'Valor total',
  Pago: 'Pagado',
  Utilizados: 'Usó',
  Unidades: 'Unidades',
  Proteção: 'Proteccion',
  Prescrições: 'Prescripciones',
  Vigência: 'Validez',
  Notificações: 'Notificaciones',
  'Últimas conversas': 'Ultimas conversaciones',
  'Selecione um tipo de documento clínico':
    'Seleccione un tipo de documento clínico',
  'Selecione um certificado': 'Seleccione un certificado',
  'Receita Simples': 'Prescripción Sencillo',
  'Receita Antimicrobiano': 'Prescripción Antimicrobiano',
  'Requisição de Exames': 'Solicitud de Exámenes',
  'Requisição de exames': 'Solicitud de Exámenes',
  'Relatório médico': 'Historia médica',
  Instituição: 'Institución',
  'Assinar Documento': 'Firma Documento',
  'Data de emissão': 'Fecha de emisión',
  Droga: 'Droga',
  'Drogas Inseridas': 'Drogas insertadas',
  'Após preencher os campos, clique em “Adicionar” para incluir a medicação na prescrição da receita':
    'Después de completar los campos, haga clic en "Agregar" para incluir el medicamento en la receta.',
  Dosagem: 'Dosis',
  'Adicionar >>': 'Agregar >>',
  Posologia: 'Dosis',
  'Certificado Digital': 'Certificado Digital',
  'Excluir Receita': 'Borrar Prescripción',
  'Enviar ao Paciente': 'Enviar al Paciente',
  'Tipo de documento': 'Tipo de documento',
  'Informações gerais': 'Informaciones generales',
  Prescrição: 'Prescripción',
  'Assinatura do documento': 'Assinatura del documento',
  Pesquisar: 'Buscar',
  'Buscar por...': 'Busquéda por...',
  'Pesquisar usuário': 'Buscar usuario',
  Redefinir: 'Reiniciar',
  Qualquer: 'Cualquier',
  Gravados: 'Grabados',
  'Documentos Clínicos': 'Documentos Clínicos',
  'Digite o nome do administrador': 'Escribelo el nombre del administrador',
  'Digite o CPF do administrador': 'Escribelo el CPF del administrador',
  'Digite o código do paciente': 'Escribelo el código del paciente',
  'Digite o nome do profissional': 'Escribelo el nombre del profesional',
  'Digite o nome do paciente': 'Escribelo el nombre del paciente',
  'Digite o nome de um organizador': 'Escribelo el nombre de un organizador',
  'Digite o CPF do organizador': 'Escribelo el CPF del organizador',
  'Digite o CPF do paciente': 'Escribelo el CPF del paciente',
  'Selecione uma data': 'Seleccione una fecha',
  Ontem: 'Ayer',
  'Últimos 7 dias': 'Últimos 7 dias',
  'Últimos 30 dias': 'Últimos 30 dias',
  'Últimos 90 dias': 'Últimos 90 dias',
  Ínicio: 'Ínicio',
  'Em progresso': 'En progreso',
  'Digite o nome de um médico': 'Escribelo el nombre de un médico',
  'atendimentos neste dia': 'servicios este día',
  'O paciente foi informado que': 'El paciente fue informado que',
  'você está na sala de espera': 'usted está en la sala de espera',
  'Aguarde o início do atendimento': 'Aguarde el início del servicio',
  'nesta sala de espera': 'en ésta sala de espera',
  'Deixar sala': 'Dejar sala',
  'O paciente está pronto': 'El paciente está listo',
  'Você já pode iniciar o atendimento': 'Usted ya puede iniciar el servicio',
  'clicando em avançar': 'clicando en avançar',
  Cadastrados: 'Cadastrados',
  'Eventos do atendimento': 'Eventos del servicio',
  Anotações: 'Notas',
  'Documentos clínicos gerados no atendimento':
    'Documentos clínicos generados en el servicio',
  'Gerar novo documento': 'Generar nuevo documento',
  Gravação: 'Grabación',
  Chat: 'Chat',
  'Gravações do atendimento': 'Grabaciones del servicio',
  'Novo documento de apoio': 'Nuevo documento de apoyo',
  'Antes de entrar na sala de espera': 'Antes de entrar en la sala de espera',
  'Você precisa concordar com os termos de uso da':
    'Usted precisa está de acuerdo con los terminos de uso da',
  'plataforma e também concordar que o atendimento poderá':
    'plataforma y también está de acuerdo que el servicio podrá',
  'ser gravado mediante escolha do médico.':
    'ser grabado mediante escolha del médico.',
  'Aceito os': 'Acepto los',
  'termos de uso.': 'terminos de uso.',
  'Aceito que o atendimento poderá ser gravado.':
    'Acepto que el servicio podrá ser grabado.',
  'O médico foi informado que': 'El médico fue informado que',
  'Aguarde nesta tela.': 'Aguarde en ésta tela.',
  'Você será avisado quando o': 'Usted será avisado quando o',
  'médico estiver pronto': 'médico estiver listo',
  'Entrar na consulta': 'Entrar en la consulta',
  'Deixar a sala': 'Dejar la sala',
  'O médico está pronto': 'El médico está listo',
  'O médico já está na sala de atendimento':
    'El médico ya está en la sala de servicio',
  'Você já pode iniciar seu atendimento': 'Usted ya puede iniciar tu servicio',
  'Insira seu nome completo.': 'Insira tu nombre completo.',
  'CPF inválido.': 'CPF no es válido.',
  'RG inválido.': 'RG no es válido.',
  'RG inválido, existe(m) digitos faltando':
    'RG no es válido, hay dígitos faltantes',
  'RG inválido, número de digitos ultrapassou o limite':
    'RG no es válido, número de dígitos excedió el límite',
  'Email inválido.': 'Email no es válido.',
  'Telefone inválido.': 'Teléfono no es válido.',
  'CEP inválido.': 'CEP no es válido.',
  'CEP inválido, existe(m) digitos faltando':
    'CEP no es válido, hay dígitos faltantes',
  'CEP inválido, número de digitos ultrapassou o limite':
    'CEP no es válido, número de dígitos excedió el límite',
  'Data de nascimento é obrigatório.': 'Fecha de nacimiento es obligatorio.',
  'Data inválida.': 'Fecha no es válido.',
  'Nome da mãe é obrigatório.': 'Nombre de la madre es obligatorio.',
  'UF inválido.': 'Estado no es válido.',
  'UF inválido, existe(m) caracteres faltando':
    'Estado no es válido, hay caracteres faltando',
  'UF inválido, número de caracteres ultrapassados':
    'Estado no es válido, número de caracteres excedió el límite',
  'Insira o nome da sua cidade.': 'Insira el nombre de tu ciudad.',
  'Insira o nome do seu bairro.': 'Insira el nombre de tu bairro.',
  'Insira o seu endereço.': 'Insira el tu endereço.',
  'Insira o número de sua residência': 'Insira el número de tu residencia',
  'Número inválido, ultrapassou o limite':
    'Número no es válido, excedió el limite',
  'Consultante inválido ou não encontrado.':
    'Consultante no es válido o no encontrado.',
  'Profissional inválido ou não encontrado.':
    'Profesional no es válido o no encontrado.',
  'Especialidade inválida ou não encontrada':
    'Especialidad inválida o no encontrada',
  'Opção inválida': 'Opção no es válido',
  'Data inválida': 'Data no es válido',
  'Hora de ínicio incorreta': 'Hora de ínicio incorreta',
  'Hora final incorreta': 'Hora final incorreta',
  'Senha inválida': 'Contraseña no es válido',
  'Insira o nome da droga': 'Insira el nombre de la droga',
  'Insira a dosagem': 'Insira la dosis',
  'Insira a posologia da medicação': 'Insira la dosis de la medicação',
  'Tempo de afastamento': 'Tiempo libre',
  Observações: 'Comentarios',
  'Motivo do afastamento': 'razón para irse',
  'Insira o tempo de afastamento': 'Insira el tiempo libre',
  'Insira uma indicação clínica.': 'Insira una indicación clínica.',
  'Exames requisitados': 'Exámenes requeridos',
  'Requisito os seguintes exames': 'Necesito los siguientes exámenes',
  'Indicação Clínica': 'Indicación clínica',
  'Insira o número do seu documento profissional':
    'Insira el número del tu documento profesional',
  'Insira a UF do seu documento profissional':
    'Insira de la Estado del tu documento profesional',
  'Insira a data de emissão do seu documento profissional':
    'Insira la fecha de emisión de tu documento profesional',
  'Selecione a área de atendimento': 'Seleccione de la área de servicio',
  'Falha no servidor, tente novamente mais tarde.':
    'Falla en servidor, intenta de nuevo más tarde.',
  'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.':
    'Tus servicios aparecerán aqui. No hay itens para ser mostrado en el momento.',
  'Assinar agora': 'Firma ahora',
  'Selecione seu certificado digital e finalize a criação do documento agora.':
    'Seleccione tu certificado digital y finalize de la creación del documento ahora.',
  'Assinar depois': 'Firma despues',
  'Este documento clinico será listado como Documento pendente e não será enviado ao paciente até que a assinatura seja finalizada.':
    'Este documento clinico será listado como Documento pendente y no será enviado al paciente até que la assinatura seja finalizada.',
  'Você não tem nenhum documento associado ao atendimento.':
    'Usted no tienes ninguno documento associado al servicio.',
  'Você não tem nenhuma gravação.': 'Usted no tienes ninguno grabación.',
  'Antes de iniciar': 'Antes de iniciar',
  'Não foi possível desativar a câmera.':
    'No fue possível desativar de la cámara.',
  'Recomendamos que entre com o microfone e câmera ligados.':
    'Recomendamos que entre con el micrófono y cámara activada.',
  'Aceite os termos de uso e de gravação para iniciar.':
    'Aceite los terminos de uso y de grabación para iniciar.',
  DESATIVADA: 'DESATIVADA',
  'Entrar com:': 'Entrar com:',
  Ligado: 'Activado',
  Desligado: 'Apagado',
  Ligada: 'Activado',
  Desligada: 'Desactivada',
  DESLIGADA: 'DESACTIVADA',
  'Câmera desligada': 'Cámara desactivada',
  'termos de uso': 'terminos de uso',
  'Aceito que o atendimento poderá ser gravado':
    'Acepto que el servicio podrá ser grabado',
  'Participar do atendimento': 'Participar del servicio',
  'Sair do atendimento': 'Salir del servicio',
  'Esqueceu sua senha?': '¿Olvidaste tu contraseña?',
  Entrar: 'Entrar',
  'Realize o login para iniciar o atendimento':
    'Inicie sesión para iniciar el servicio',
  'do paciente': 'del paciente',
  'marcado para as': 'programado para',
  'Recuperar senha': 'Recuperar contraseña',
  'Digite e confirme sua nova senha nos campos abaixo.':
    'Ingrese y confirme su nueva contraseña en los campos a continuación.',
  'Insira seu e-mail no campo abaixo e lhe enviaremos um link para redefinir sua senha.':
    'Ingrese su correo electrónico en el campo a continuación y le enviaremos un enlace para restablecer su contraseña.',
  'um link para alteração de senha': 'un enlace para cambiar la contraseña',
  'Atualmente você está como': 'Actualmente eres un',
  'Acesso para convidado': 'Acceso para visitante',
  'Você irá participar como convidado do atendimento do(a) Paciente':
    'Participará como invitado en la atención del Paciente.',
  'Identifique-se': 'Identificate',
  'Insira seus dados para entrar no atendimento':
    'Ingrese sus datos para ingresar al servicio',
  em: 'en',
  'Para alterar sua função, selecione o papel em uma organização':
    'Para cambiar su rol, seleccione el rol en una organización',
  'Defina seu papel na organização desejada.':
    'Defina su rol en la organización deseada.',
  Confirmar: 'Confirmar',
  Carregando: 'Cargando',
  'Seus documentos clínicos (receitas, atestados etc) aparecerão aqui. No momento você não tem nenhum':
    'Sus documentos clínicos (recetas, certificados, etc.) aparecerán aquí. Por el momento no tienes ninguna',
  'Primeiro atendimento': 'Primero servicio',
  Nenhum: 'Ninguno',
  'Os pacientes cadastrados aparecerão aqui. No momento não há nenhum':
    'Los pacientes registrados aparecerán aquí. Actualmente no hay ninguno',
  'Os profissionais cadastrados aparecerão aqui. No momento não há nenhum':
    'Los profesionales registrados aparecerán aquí. Actualmente no hay ninguno',
  'Os organizadores cadastrados aparecerão aqui. No momento não há nenhum':
    'Los organizadores registrados aparecerán aquí. Actualmente no hay ninguno',
  'Os administradores cadastrados aparecerão aqui. No momento não há nenhum':
    'Los administradores registrados aparecerán aquí. Actualmente no hay ninguno',
  Convênio: 'Seguro de Salud',
  'Salvando...': 'Guardando...',
  'Registro (conselho regulador)': 'Registro (junta reguladora)',
  Especialidades: 'Especialidades',
  'Registro do conselho': 'Registro de la junta',
  Documento: 'Documento',
  'Cadastrar novo administrador': 'Registrar nuevo administrador',
  'Criar novo setor': 'Crear nuevo sector',
  Em: 'En',
  'Deseja agendar um novo atendimento?':
    '¿Quieres programar un nuevo servicio?',
  'Novo atendimento': 'Nuevo servicio',
  às: 'a',
  'anos e': 'años y',
  meses: 'meses',
  'Editar consulta': 'Editar servicio',
  'Links e Convidados': 'Enlaces e invitados',
  'Área do atendimento': 'Área de servicios',
  'Adicionar convidado': 'Agregar invitado',
  'Digite o e-mail ou o número do celular para convidar':
    'Ingrese el correo electrónico o el número de teléfono móvil para invitar',
  interno: 'interno',
  externo: 'externo',
  Participante: 'Participante',
  'Data do envio': 'Fecha de envío',
  Ação: 'Acción',
  'Só você pode ver, editar e excluir a anotação do atendimento.':
    'Solo usted puede ver, editar y eliminar la nota de servicio.',
  'Escreva aqui': 'Escribe aquí',
  'Administradores cadastrados': 'Administradores registrados',
  'Novo administrador': 'Nuevo administrador',
  Inativo: 'Inactivo',
  Suspenso: 'Suspendido',
  Realizados: 'Realizado',
  'Não foi possível assinar o documento.':
    'No fue posible firmar el documento.',
  'Enviado ao paciente:': 'Enviado al paciente:',
  'Atesto que o(a) Sr(a).': 'Te certifico que tú.',
  'encontra-se sob meus cuidados profissionais necessitando de afastamento de suas atividades pelo período de':
    'usted está bajo mi cuidado profesional y necesita estar lejos de sus actividades durante el período de',
  'Observações:': 'Nota',
  'Indicação clínica:': 'Indicación clínica:',
  'Solicito:': 'Solicito:',
  'Requisito o exame': 'Necesito el examen',
  'para o paciente pois o mesmo está com sintomas.':
    'para el paciente porque tiene síntomas.',
  'Selecione um arquivo': 'Seleccione un archivo',
  receituario: 'prescripción',
  'Ocorreu um erro ao excluir o documento clínico':
    'Hubo un error al eliminar el documento clínico.',
  'Ocorreu um erro ao reenviar o e-mail, tente novamente mais tarde':
    'Se produjo un error al reenviar el correo electrónico. Vuelva a intentarlo más tarde.',
  'Visualizar PDF': 'Ver PDF',
  Baixar: 'Descargar',
  'Assinar documento': 'Firma el documento',
  'Reenviar por e-mail': 'Reenviar por correo electrónico',
  Excluir: 'Borrar',
  'Adicionar profissional externo': 'Agregar profesional externo',
  'profissional interno': 'Profesional Interno',
  'profissional externo': 'Profesional Externo',
  'Adicione uma ou mais especialidades para concluir o cadastro':
    'Agregue una o más especialidades para completar el registro',
  'Suas especialidades aparecerão aqui': 'Sus especialidades aparecerán aquí',
  Aguarde: 'Espera',
  'será atendido(a) em alguns instantes': 'será atendido en unos momentos',
  'O atendimento será iniciado assim que o médico e paciente estiverem na sala.':
    'El servicio comenzará tan pronto como el médico y el paciente estén en la habitación.',
  'Cadastro realizado com sucesso!': '¡Registro exitoso!',
  'Enviamos um email de confirmação para':
    'Enviamos un correo electrónico de confirmación a',
  'Falta pouco: para habilitar sua conta, acesse a mensagem enviada e clique no link de confirmação.':
    'Solo falta: para habilitar su cuenta, acceda al mensaje que envió y haga clic en el enlace de confirmación.',
  OK: 'OK',
  'Sair sem salvar': 'Salir sin guardar',
  'Se você sair agora, as alterações feitas':
    'Si cierra la sesión ahora, los cambios realizados',
  'não serão salvas.': 'no se salvará.',
  'Para salvá-las, clique no botão Salvar antes de sair.':
    'Para guardarlos, haga clic en el botón Guardar antes de salir.',
  'Voltar para edição': 'Volver a editar',
  'Alterações salvas com sucesso!': '¡Los cambios se guardaron correctamente!',
  'Não excluir': 'No eliminar',
  'Sim, excluir': 'Sí, eliminar',
  'Atendimento agendado com sucesso!': '¡Servicio programado correctamente!',
  'Link para o profissional': 'Enlace al profesional',
  'Link para o paciente': 'Enlace al paciente',
  'O atendimento foi cancelado. Consulte o médico ou paciente para mais informações.':
    'El servicio ha sido cancelado. Consulte al médico o al paciente para obtener más información.',
  'WhatsApp (opcional)': 'WhatsApp (opcional)',
  'Deseja finalizar o atendimento?': '¿Quiere finalizar el servicio?',
  'Caso opte por não finalizar o atendimento agora, você poderá finalizá-lo posteriormente.':
    'Si elige no finalizar el servicio ahora, puede finalizarlo más tarde.',
  'Não finalizar': 'No terminar',
  'Sim, finalizar agora': 'Sí, terminar ahora',
  'Pronto!': '¡Listo!',
  'Você já pode participar do atendimento':
    'Ahora puede participar en el servicio',
  'Clique em entrar para iniciar': 'Haga clic en iniciar sesión para comenzar',
  Copiar: 'Copiar',
  'Copiado!': 'Copiado!',
  'Excluir setor?': '¿Eliminar sector?',
  'Você deseja excluir o documento?': '¿Quieres borrar el documento?',
  'Excluir documento pendente?': '¿Eliminar documento pendiente?',
  'Deseja realmente excluir este documento?':
    '¿Realmente desea eliminar este documento?',
  'Atendimento cancelado com sucesso!': '¡Servicio cancelado con éxito!',
  'Documento clínico criado com sucesso!':
    '¡Documento clínico creado con éxito!',
  'Documento clínico enviado com sucesso!':
    '¡Documento clínico enviado con éxito!',
  'Documento assinado com sucesso!': '¡Documento firmado correctamente!',
  'Usuario excluido com sucesso!': '¡Usuario eliminado correctamente!',
  'Modelos de documentos clínicos': 'Plantillas de documentos clínicos',
  'Documentos suportados para envio: jpeg, png, pdf e exe.':
    'Documentos admitidos para envío: jpeg, png, pdf y exe.',
  'Lista de documentos de apoio do atendimento':
    'Lista de documentos de apoyo para el servicio',
  'Novo setor': 'Nuevo sector',
  Setor: 'Sector',
  'Total de pacientes': 'Pacientes totales',
  'Total de profissionais': 'Profesionales totales',
  'Armazenamento este mês': 'Almacenamiento este mes',
  'Setor excluido com sucesso!': 'Sector eliminado con éxito!',
  'Não foi possível excluir setor, tente novamente.':
    'No se pudo borrar el sector. Vuelve a intentarlo.',
  'Credenciais inválidas!': '¡Credenciales no válidas!',
  'Falha ao realizar login!': '¡Error al iniciar sesión!',
  'Documento enviado com sucesso!': '¡Documento enviado con éxito!',
  'Falha ao registrar usuário.': 'No se pudo registrar el usuario.',
  'E-mail ou CPF já utilizado.': 'Correo electrónico o CPF ya utilizados.',
  'Criação de conta confirmada!': '¡Creación de cuenta confirmada!',
  'Recuperação de senha bem-sucedida!': '¡Recuperación de contraseña exitosa!',
  'Email enviado com sucesso!': '¡Email enviado con éxito!',
  'O agendamento já foi confirmado.': 'El horario ya ha sido confirmado.',
  'Agendamento confirmado com sucesso.': 'Cita confirmada con éxito.',
  'Falha ao confirmar o atendimento.': 'No se pudo confirmar el servicio.',
  'Falha ao cancelar o atendimento.': 'No se pudo cancelar el servicio.',
  'Falha ao criar agendamento!': '¡No se pudo crear el horario!',
  'Falha ao remover agendamento.': 'No se pudo eliminar la programación.',
  'Falha ao tentar atualizar agendamento.':
    'No se pudo intentar actualizar la programación.',
  'Token expirado! Efetue o login novamente para trocar papéis.':
    '¡Contador caducado! Inicie sesión de nuevo para cambiar de rol.',
  'Falha ao criar documento clínico.': 'No se pudo crear el documento clínico.',
  'Falha ao remover documento clínico.':
    'No se pudo eliminar el documento clínico.',
  'Falha ao assinar documento clínico.': 'No firmar documento clínico.',
  'Falha ao fazer upload do arquivo.': 'No se pudo cargar el archivo.',
  'Não foi possível atualizar o usuário. Você digitou os campos corretamente?':
    'No se puede actualizar al usuario. ¿Escribiste los campos correctamente?',
  'Falha interna no servidor, tente novamente em minutos.':
    'Fallo interno del servidor, inténtelo de nuevo en minutos.',
  'entrou no atendimento de data': 'entró en el servicio de citas',
  'entrou no atendimento': 'entró al servicio',
  'Falha ao adicionar agendamento a lista de atendimentos!':
    '¡No se pudo agregar el horario a la lista de servicios!',
  'Não foi possível carregar as conversas':
    'No se pudieron cargar las conversaciones',
  'Digite sua senha atual.': 'Introduce tu contraseña actual.',
  'Confirmação de senha incorreta.': 'Confirmación de contraseña incorrecta.',
  'Falha ao tentar atualizar. Verifique os dados e tente novamente':
    'No se pudo intentar actualizar. Verifique los datos y vuelva a intentarlo',
  'Falha na busca dos estados': 'Error al buscar estados',
  'Falha na busca das cidades': 'No se pudieron buscar ciudades',
  'Link copiado para área de transferência': 'link copiado al portapapeles',
  'Convite enviado com sucesso!': '¡Invitación enviada con éxito!',
  'Falha ao enviar convite!': '¡No se pudo enviar la invitación!',
  'Convite reenviado com sucesso!': '¡Invitación reenviada con éxito!',
  'Falha ao reenviar convite!': 'No se pudo reenviar la invitación.',
  'Não é possível enviar o documento antes de assinado!':
    '¡No es posible enviar el documento antes de que esté firmado!',
  'Não foi possível atualizar especialidades, tente novamente':
    'No se pueden actualizar las ofertas especiales, inténtalo de nuevo',
  'Especialidades atualizadas com sucesso!':
    '¡Especialidades actualizadas con éxito!',
  'Não foi possível atualizar especialidades, tente novamente.':
    'No se pueden actualizar las ofertas especiales. Vuelve a intentarlo.',
  'É preciso adicionar pelo menos um profissional':
    'Debes agregar al menos un profesional',
  'Você precisa adicionar uma especialidade.':
    'Necesita agregar una especialidad.',
  'Erro ao criar o profissional. Tente novamente.':
    'Error al crear el profesional. Inténtalo de nuevo.',
  'Não foi possivel encontrar nenhum certificado':
    'No se pudo encontrar ningún certificado',
  'Atualize os dados da sua prescrição.':
    'Actualice sus datos de prescripción.',
  'Erro ao confirmar agendamento. Você realmente agendou um horário?':
    'Error al confirmar la cita. ¿Realmente concertaste una cita?',
  'Não foi possível entrar na conferência, tente novamente':
    'No se pudo unir a la conferencia. Vuelva a intentarlo.',
  'Você realmente solicitou a criação de conta?':
    '¿Realmente solicitó crear una cuenta?',
  'Digite e confirme sua nova senha.':
    'Ingrese y confirme su nueva contraseña.',
  'Você realmente é um novo usuário?': '¿Eres realmente un usuario nuevo?',
  'Código não encontrado.': 'Código no encontrado.',
  'Não foi possível obter informações da sala de espera, tente novamente':
    'No se puede obtener la información de la sala de espera, inténtelo de nuevo.',
  'Preencha os campos corretamente.': 'Complete los campos correctamente.',
  'Email inválido! Você já está cadastrado no sistema?':
    '¡Email inválido! ¿Ya estás registrado en el sistema?',
  'Você realmente solicitou a alteração de senha?':
    '¿Realmente solicitó un cambio de contraseña?',
  'Não foi possível atualizar imagem do setor.':
    'No se puede actualizar la imagen del sector.',
  'Setor editado com sucesso!': 'Sector editado correctamente!',
  'Setor criado com sucesso!': 'Sector creado correctamente!',
  'Não foi possível criar setor.': 'No se pudo crear sector.',
  'Voltar para a listagem de setores': 'Volver a la lista de sectores',
  'Já existe um setor com esse nome.': 'Ya existe un sector con ese nombre.',
  'Não foi possível atualizar setor.': 'No se pudo actualizar el sector.',
  'Não foi possível associar imagem ao setor.':
    'No fue posible asociar imagen con el sector.',
  'Seu vídeo está sendo baixado!': '¡Tu video se está descargando!',
  'Erro ao fazer download!': '¡Error al descargar!',
  'Diminuir nota': 'Disminuir la anotación',
  'Expandir nota': 'Expandir anotación',
  'Escreva aqui as anotações sobre o paciente.':
    'Escriba notas sobre el paciente aquí.',
  'Salvamento automático': 'Guardado automático',
  'Preenchimento de dados': 'Datos de relleno',
  'Identificamos que o usuário já está cadastrado na plataforma. Deseja preencher automaticamente os campos com os seus dados?':
    'Identificamos que el usuario ya está registrado en la plataforma. ¿Quieres rellenar automáticamente los campos con tus datos?',
  Atenção: 'Aviso',
  'O usuário já está cadastrado neste setor.':
    'El usuario ya está registrado en este sector.',
  'O usuário já está cadastrado nesta organização.':
    'El usuario ya está registrado en esta organización.',
  'É necessário que pelo menos um setor seja selecionado.':
    'Es necesario que al menos un sector seleccionado.',
};
