import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import MediaError from './MediaError';
import { makeRemoteGetClinicalDocsByDownload } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsByDownloadFactory';
import { Content } from './styles/StyledMediaViewer';
import { AlertMessage } from '../messages/AlertMessage';

interface Media {
  link?: string;
  id?: string;
}

const MediaViewer: React.FC<Media> = ({ link, id }) => {
  const [error, setError] = useState(false);

  const requestfile = useCallback(() => {
    if (link !== undefined) {
      return axios.get(`${link}`, {
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      });
    }

    return makeRemoteGetClinicalDocsByDownload().getByDownload({
      docId: parseInt(`${id}`, 10),
    });
  }, [id, link]);

  const loadfile = useCallback(async () => {
    try {
      const data = await requestfile();

      const fileURL = URL.createObjectURL(data);

      const iframe = document.createElement('iframe');
      iframe.src = fileURL;
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.allowFullscreen = true;

      const div = document.getElementById('containerPDF');
      if (div?.lastElementChild) div?.removeChild(div.lastElementChild);
      div?.appendChild(iframe);

      setError(false);
    } catch (e) {
      setError(true);
    }
  }, [requestfile]);

  useEffect(() => {
    loadfile();
  }, [link, id, loadfile]);

  return <Content id="containerPDF">{error && <MediaError />}</Content>;
};

export default MediaViewer;
