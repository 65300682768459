import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { Chat } from '~/presentation/components/chat';
import { iStore } from '~/domain/interfaces/models/Store';
import {
  ProfessionalPages,
  ManagerPages,
  ParticipantPages,
} from '~/presentation/pages';
import { makeReduxGetAllNotificationUser } from '~/main/factories/usecases/Notifications/GetAllNotificationUserFactory';
import { PageNotFound } from '~/presentation/pages/errors/index';
import {
  makeRemoteGetAllChat,
  makeReduxGetAllChat,
} from '~/main/factories/usecases/chat/GetAllChatFactory';
import { makeRemoteGetAllChatMessage } from '~/main/factories/usecases/chat/GetAllChatMessageFactory';
import { makeReduxSetupChat } from '~/main/factories/usecases/chat/SetupChatFactory';
import { makeRemoteCreateChatMessage } from '~/main/factories/usecases/chat/CreateChatMessageFactory';
import { makeReduxGetAllSpecialty } from '../../usecases/specialty/GetAllSpecialtyFactory';
import { makeReduxGetAllConsultant } from '../../usecases/consultant/GetAllConsultant';
import { makeReduxGetAllProfessionals } from '../../usecases/professional/GetAllProfessionalFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { getAppointmentsByDate } from '~/utils/getAppointmentsByDate';
import {
  makeReduxGetAllProfession,
  makeReduxProfessionList,
} from '../../usecases/profession';
import { History } from '~/main/routes';
import { makeReduxGetAllOrganizer } from '../../usecases/organizer/GetAllOrganizer';
import { makeReduxGetAllAdministrator } from '~/main/factories/usecases/administrator/GetAllAdministratorFactory';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { makeReduxSetData } from '../../usecases/appointment/SetDataFactory';
import { makeReduxActiveMessage } from '../../usecases/message/Update';
import { translator } from '~/presentation/components/i18n';

// import AppointmentConsultant from '~/presentation/roles/participant/pages/appointment';
interface iParamsTypes {
  language: string;
}

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export const MakeAppointmentPage: React.FC = () => {
  const { orgId, orgUnitId, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const { info: userLogged, access } = useSelector(
    (store: iStore) => store.auth,
  );
  const { loading, totalItems, date } = useSelector(
    (store: iStore) => store.appointment,
  );

  const { selected } = useSelector((state: iStore) => state.professions);
  const { language } = useParams<iParamsTypes>();

  useEffect(() => {
    makeReduxGetAllChat().getAll({});
    makeReduxProfessionList().post({});
    makeReduxGetAllSpecialty().getAll({
      pageSize: 9999,
      filter: { hasProfessional: true, enabled: true, profession: selected },
    });
    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        situation: ['ACTIVE', 'INACTIVE'],
      },
    });
    makeReduxGetAllProfessionals().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        unit: orgUnitId ?? undefined,
        situation: ['ACTIVE'],
      },
    });
    makeReduxGetAllOrganizer().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: orgUnitId ?? undefined,
        situation: ['ACTIVE'],
      },
    });

    makeReduxGetAllAdministrator().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        situation: ['ACTIVE'],
      },
    });

    makeReduxGetAllOrgUnitList().list({
      filter: {
        org: orgId,
      },
    });

    async function getChat() {
      // console.log(await makeRemoteGetAllChat().getAll({ userId: 43 }));
      // await makeRemoteGetAllChatMessage()
      //   .getAllMessage({ chatId: 17 })
      //   .then(res => console.log(res))
      //   .catch(err =>
      //     AlertMessage({
      //       message: intl.formatMessage({
      //         id: 'Não foi possível carregar as conversas',
      //       }),
      //       type: 'info',
      //     }),
      //   );
      /* makeRemoteCreateChatMessage().createMessage({
        id: 17,
        type: 'Text',
        content: 'Teste de envio',
        emitter: 43,
      }); */
    }

    // getChat();
  }, [access, selected]);

  useEffect(() => {
    getAppointmentsByDate(
      date ? new Date(date).toISOString() : new Date().toISOString(),
      userLogged,
    );
  }, [date]);

  useEffect(() => {
    if (language && language.length === 5 && language.split('-').length === 2) {
      localStorage.setItem('i18nextLng', language);
      History.getHistory().push('/appointment');
      window.location.reload();
    } else if (language) {
      History.getHistory().replace('/appointment');
    }
  }, [language]);

  useEffect(() => {
    // TODO: Não reiniciar a data quando voltar dos detalhes do atendimento.
    makeReduxSetData().set({ date: new Date().toISOString() });
    makeReduxGetAllNotificationUser().getAll({
      filters: {
        user: userLogged.user?.id,
        channel: 'WEB',
      },
    });
  }, []);

  makeReduxSetupChat().setup({
    visible: false,
    mainUser: {
      id: userLogged.user?.id,
      firstName: userLogged.user?.firstName,
      lastName: userLogged.user?.lastName,
    },
  });

  // if (loading) return <V4hSpin fullScreen background="white" />;

  const loadPage = (r: string | undefined) => {
    console.log('... role', r);
    switch (r) {
      case 'ADM':
        return <ManagerPages.Appointment totalAppointment={totalItems} />;
      case 'ORG':
        return <ManagerPages.Appointment totalAppointment={totalItems} />;
      case 'PRO':
        return <ProfessionalPages.Appointment totalAppointment={totalItems} />;
      case 'CON':
        return <ParticipantPages.Appointment totalAppointment={totalItems} />;
      default:
        return <PageNotFound />;
    }
  };

  return (
    <>
      {/* <Chat /> */}
      {loadPage(role)}
    </>
  );
};
