import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import HeaderExpiration from '~/presentation/components/HeaderExpiration/HeaderExpiration';

import { withAccess } from '~/presentation/hooks/access';
import { HeaderSimples } from '~/presentation/components/header';
import { SearchListAppointment } from '~/presentation/components/search-list';

import { ListAppointment } from '~/presentation/components/ListProfessional';
import { Menu } from '~/presentation/components/menu';
import { translator } from '~/presentation/components/i18n';
import { Input, Button } from '~/presentation/components/UI';
import { HistoryChat } from '~/presentation/components/historyChat';
import { PaginationByDate } from '~/presentation/components/paginationByDate';
import { Chat as Cchat } from '~/presentation/components/chat';
import { History } from '~/main/routes';

import {
  IconCalendar,
  IconChatBalon,
  IconPlus,
} from '~/presentation/base/icons';
import Calendar from '~/presentation/components/calendar';

import { FilterAppointment } from '~/presentation/components/filter';

import {
  Container,
  Body,
  Chat,
  Inputs,
  IconBalon,
  HistoryComponent,
  Attendiment,
  ButtonBig,
} from './StyledAppointment';

interface ownProps {
  totalAppointment: number;
}

const Appointment: React.FC<ownProps> = ({ totalAppointment }): JSX.Element => {
  const Navigator = withAccess(Link);

  const message = useSelector((store: iStore) => store.message);

  const { orgs } = useSelector((store: iStore) => store.auth);

  const expiration = useMemo(() => {
    const temp = orgs?.[0]?.expiration ?? null;

    if (temp) {
      return Math.floor(
        (new Date(temp).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
      );
    }

    return temp;
  }, [orgs]);

  return (
    <Container
      style={{
        opacity:
          message.active !== 'none' && message.active !== 'professionalReady'
            ? '0.7'
            : '1',
      }}
    >
      <Cchat />
      <Menu />
      <HeaderSimples
        title={translator('Agenda de atendimentos')}
        icon={IconCalendar}
        value={totalAppointment}
        valueDescription={translator('atendimentos neste dia')}
      />

      {expiration && <HeaderExpiration daysUse={`${expiration} dias`} />}

      <Body>
        <div id="left">
          <Calendar />
          {/* <Chat>
            Chat
            <IconBalon>
              <IconChatBalon />
            </IconBalon>
          </Chat>
          <Inputs>
            <Input placeholder={`${translator('Pesquisar usuário')}`} />
          </Inputs>
          <HistoryComponent>
            <HistoryChat />
          </HistoryComponent> */}
        </div>
        <div id="right">
          <div id="header">
            <PaginationByDate />
            <SearchListAppointment>
              <FilterAppointment />
            </SearchListAppointment>
            <Navigator
              to={{
                pathname: '/appointment/new',
                state: {
                  from: window.location.pathname.replaceAll('/', ''),
                },
              }}
              resourcers={['APPOINTMENT']}
              actions={['CREATE_ORG', 'CREATE_OWN', 'CREATE_ANY']}
            >
              <ButtonBig>
                <Button
                  id="btn_create_appointment"
                  className="btnAction"
                  size="bigger"
                  rounded
                >
                  <Attendiment>
                    <IconPlus />
                  </Attendiment>
                  {translator('Novo agendamento')}
                </Button>
              </ButtonBig>
            </Navigator>
          </div>
          <div id="list">
            <ListAppointment />
          </div>
        </div>
      </Body>
    </Container>
  );
};

export default Appointment;
