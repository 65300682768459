import React from 'react';

import * as Appointment from './appointment';
import * as User from './user';
import Delete from './exclude';
import Success from './success';

import { ownProps } from './interface';
import { ConnectComponent } from './mapper/mapperMessage';
import ModalExpiredFremium from '../modalExpiredFremium/ModalExpiredFremium';

const Notifications: React.FC<ownProps> = ({
  children,
  message,
}): JSX.Element => {
  return (
    <>
      <Appointment.AppointmentReady message={message} />
      <Appointment.AppointmentConfirmStop message={message} />
      <Appointment.ConfirmCancellation message={message} />
      <Appointment.ScheduleExclude message={message} />
      <Appointment.SuccessfullyCreated message={message} />
      <Appointment.TermsOfUse message={message} />
      <Appointment.EditConsultCopyLink message={message} />
      <Appointment.InviteExternalProfessionals message={message} />
      <User.UserRegisterSuccess message={message} />
      <User.WaitingMessage message={message} />
      <User.WarningMessage message={message} />
      <User.UserLeaveSpecialty message={message} />
      <User.UserChangeRole message={message} />
      <User.UserAlreadyExistsOnSector message={message} />
      <User.UserDataFulfill message={message} />
      <User.UserEditDataError message={message} />
      <Delete message={message} />
      <Success message={message} />
      <ModalExpiredFremium message={message} />
      {children}
    </>
  );
};

export default ConnectComponent(Notifications);
