import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';

import { IconCalendar } from '~/presentation/base/icons';
import { PageHeader } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import { Menu } from '~/presentation/components/menu';
import { RegiterAppointment } from '~/presentation/components/registerAppointment';
import { Container } from './styles';

const NewAppointment: React.FC = () => {
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);
  const { selected } = useSelector((state: iStore) => state.professions);

  useEffect(() => {
    makeReduxGetAllSpecialty().getAll({
      pageSize: 9999,
      filter: { hasProfessional: true, enabled: true, profession: selected },
    });

    makeReduxGetAllOrgUnitList().list({
      filter: {
        org: orgId,
      },
    });
  }, [orgId, selected]);

  return (
    <Container>
      <Menu />
      <PageHeader
        title={translator('Agendar novo atendimento')}
        icon={IconCalendar}
        confirmChanges
      />
      <RegiterAppointment />
    </Container>
  );
};

export default NewAppointment;
