import styled from 'styled-components';

export const Container = styled.div`
  background: #fcfcfc;
  width: 100%;
  color: #656a6e;
  display: flex;
  justify-content: space-between;

  #left,
  #center,
  #right {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
  }

  #left {
    border: none;
    background: transparent;
    display: flex;
    gap: 20px;
    padding-left: 30px;
    justify-content: flex-start;
    max-width: fit-content;
    color: ${props => props.theme.primaryDark};
    font-weight: bold;
    :hover {
      cursor: pointer;
    }
  }
`;

export const Title = styled.div`
  font-weight: 700;
  /* margin-left: 8px; */
  padding-right: 8px;
`;
