import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iStore } from '~/domain/interfaces/models';
import {
  GetAddressData as RemoteGetAddressData,
  GetCitiesByUf as RemoteGetCitiesByUf,
  GetUfs as RemoteGetUfs,
} from '~/domain/usecases/externalServices/remote';
import { makeRemoteGetAddressData } from '~/main/factories/usecases/externalServices/GetAddressData';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import { makeRemoteGetUfs } from '~/main/factories/usecases/externalServices/GetUfs';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import { Select } from '~/presentation/components/UI';
import { translator } from '../i18n';
import { AlertMessage } from '../messages/AlertMessage';
import Input from '../UI/input';
import {
  Container,
  Group,
  GroupCity,
  GroupNeighborhood,
  Title,
} from './style/StyledAddress';

interface ownProps {
  form: any;
  state: any;
  disableAutoFocus?: boolean;
}

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const Address: React.FC<ownProps> = ({
  form,
  state,
  disableAutoFocus = false,
}) => {
  const { errors, register, setValue, getValues, watch, trigger } = form;
  const { loading } = useSelector((store: iStore) => store.users);
  const { profile } = useSelector((store: iStore) => store.showModal);
  const [data, setdata] = useState(state);
  const [ufs, setUfs] = useState<RemoteGetUfs.Model>([]);
  const [cities, setCities] = useState<RemoteGetCitiesByUf.Model>([]);
  const [selectedUf, setSelectedUf] = useState('');
  const [formData, setFormData] = useState({
    ...getValues(),
  });

  useEffect(() => {
    setdata(state);
  }, [state]);

  useEffect(() => {
    makeRemoteGetUfs()
      .getUfs({})
      .then(res => {
        setUfs(res);

        if (state?.address?.uf) {
          setFormData({ ...form, uf: state?.address?.uf });
          setSelectedUf(state?.address?.uf);
        }
      })
      .catch(err => {
        AlertMessage({
          message: intl.formatMessage({ id: 'Falha na busca dos estados' }),
          type: 'danger',
        });
      });

    makeReduxSetShowModal().set({ profile: false });
  }, []);

  const ufSelected = watch('uf');

  useEffect(() => {
    if (ufSelected) {
      makeRemoteGetCitiesByUf()
        .getCitiesByUf({ uf: ufSelected })
        .then(res => {
          setCities(res);

          const findCity = res.find(
            city => city.nome === getValues()?.city,
          )?.nome;

          if (findCity) setValue('city', findCity, { shouldValidate: true });
        })
        .catch(err => {
          AlertMessage({
            message: intl.formatMessage({ id: 'Falha na busca das cidades' }),
            type: 'danger',
          });
        });
    }
  }, [ufSelected, getValues()?.zipcode]);

  const onChangeZipcode = (value: string) => {
    if (!profile) makeReduxSetShowModal().set({ profile: true });

    setFormData({ ...formData, zipcode: value });
    setValue('zipcode', value);

    if (value.length >= 8) {
      const dataToSend: RemoteGetAddressData.Params = {
        zipcode: value,
      };

      makeRemoteGetAddressData()
        .getAddressData(dataToSend)
        .then(response => {
          console.log('Informações do CEP: ', response);

          const updatedForm = {
            uf: response.uf,
            city: response.localidade,
            neighborhood: response.bairro,
            street: response.logradouro,
            zipcode: value,
          };

          setValue('uf', response.uf);
          setValue('city', response.localidade);
          setValue('neighborhood', response.bairro);
          setValue('street', response.logradouro);
        })
        .catch(error => {
          console.log('Erro: ', error);
        });
    }
  };

  const onChangeState = (value: string) => {
    if (!profile) makeReduxSetShowModal().set({ profile: true });

    setFormData({ ...formData, uf: value });
    setSelectedUf(value);
    setValue('uf', value);
  };

  const onChangeCity = (value: string) => {
    if (!profile) makeReduxSetShowModal().set({ profile: true });

    setFormData({ ...formData, city: value });
    setValue('city', value);
  };

  const onChangeNeighborhood = (value: string) => {
    if (!profile) makeReduxSetShowModal().set({ profile: true });

    setFormData({ ...formData, neighborhood: value });
    setValue('neighborhood', value);
  };

  const onChangeStreet = (value: string) => {
    if (!profile) makeReduxSetShowModal().set({ profile: true });

    setFormData({ ...formData, street: value });
    setValue('street', value);
  };

  const onChangeComplement = (value: string) => {
    if (!profile) makeReduxSetShowModal().set({ profile: true });

    setFormData({ ...formData, complement: value });
    setValue('complement', value);
  };

  return (
    <Container>
      <Title>{translator('Endereço')}</Title>
      <InputMask
        key={data.address.zipcode}
        mask="99999-999"
        name="zipcode"
        height="40px"
        defaultValue={data.address.zipcode}
        value={getValues()?.zipcode}
        ref={() => register('zipcode')}
        onChange={e => {
          onChangeZipcode(e.target.value.replace(/[^\d]+/g, '').trim());
        }}
        disabled={loading}
      >
        <Input
          id="registerZipCode"
          name="zipcode"
          placeholder={translator('Digite seu CEP')}
          label="CEP"
          error={Boolean(errors.zipcode)}
          message={
            errors?.zipcode?.message ? translator(errors?.zipcode?.message) : ''
          }
          required
          autoFocus={!disableAutoFocus}
          disabled={loading}
        />
      </InputMask>
      <Input
        key={data.address.street}
        name="address"
        label={translator('Endereço')}
        height="40px"
        value={getValues()?.street}
        defaultValue={data.address.street}
        onChange={e => onChangeStreet(e.target.value)}
        register={() => register('street')}
        error={Boolean(errors.street)}
        message={errors?.street?.message}
        required
        disabled={loading}
      />
      <Group>
        <Input
          key={data.address.number}
          name="number"
          label={translator('Número')}
          height="40px"
          // value={getValues()?.number}
          defaultValue={data.address.number}
          onChange={e => {
            if (!profile) makeReduxSetShowModal().set({ profile: true });

            setValue('number', e.target.value.trim());
          }}
          register={() => register('number')}
          error={Boolean(errors.number)}
          message={errors?.number?.message}
          required
          disabled={loading}
        />
        <Input
          key={data.address.complement}
          name="complement"
          label={translator('Complemento')}
          height="40px"
          value={getValues()?.complement}
          defaultValue={data.address.complement}
          onChange={e => onChangeComplement(e.target.value)}
          register={() => register('complement')}
          error={Boolean(errors.complement)}
          message={errors?.complement?.message}
          disabled={loading}
        />
      </Group>
      <GroupNeighborhood>
        <Input
          key={data.address.neighborhood}
          name="neighborhood"
          label={translator('Bairro')}
          height="40px"
          value={getValues()?.neighborhood}
          defaultValue={data.address.neighborhood}
          onChange={e => onChangeNeighborhood(e.target.value)}
          register={() => register('neighborhood')}
          error={Boolean(errors.neighborhood)}
          message={errors?.neighborhood?.message}
          required
          disabled={loading}
        />
      </GroupNeighborhood>
      <GroupCity>
        {cities && cities.length > 0 ? (
          <Select
            key={data.address.city}
            name="city"
            label={translator('Cidade')}
            height="40px"
            value={getValues()?.city}
            defaultValue={data.address.city}
            onChange={e => onChangeCity(e.target.value.trim())}
            register={() => register('city')}
            error={Boolean(errors.city)}
            message={errors?.city?.message}
            required
          >
            {cities &&
              cities.length > 0 &&
              cities.map(city => (
                <option key={city.id} value={city.nome}>
                  {city.nome}
                </option>
              ))}
          </Select>
        ) : (
          <Input
            key={data.address.city}
            name="city"
            label={translator('Cidade')}
            height="40px"
            value={getValues()?.city}
            defaultValue={data.address.city}
            onChange={e => onChangeCity(e.target.value)}
            register={() => register('city')}
            error={Boolean(errors.city)}
            message={errors?.city?.message}
            required
          />
        )}
        {ufs && ufs.length > 0 ? (
          <Select
            key={data.address.uf}
            name="uf"
            label={translator('UF')}
            height="40px"
            value={getValues()?.uf}
            defaultValue={data.address.uf}
            onChange={e => onChangeState(e.target.value.trim())}
            register={() => register('uf')}
            error={Boolean(errors.uf)}
            message={errors?.uf?.message}
            required
          >
            {ufs &&
              ufs.length > 0 &&
              ufs.map(uf => (
                <option key={uf.id} value={uf.sigla}>
                  {uf.sigla}
                </option>
              ))}
          </Select>
        ) : (
          <Input
            key={data.address.uf}
            name="uf"
            label={translator('UF')}
            height="40px"
            value={getValues()?.uf}
            defaultValue={data.address.uf}
            onChange={e => onChangeState(e.target.value)}
            register={() => register('uf')}
            error={Boolean(errors.uf)}
            message={errors?.uf?.message}
            required
          />
        )}
      </GroupCity>
    </Container>
  );
};

export default Address;
