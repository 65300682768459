/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';

import * as _ from 'lodash';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { IconBag } from '~/presentation/base/icons';
import {
  Container,
  Header,
  Body,
  Title,
  Footer,
} from './style/StyledProfessionalData';

import { translator } from '../i18n';
import { Icon } from '../icon';
import { Select, Button } from '../UI';
import { iProfessions, iSpecialty } from './interface';
import { Specialty } from '../specialty';
import { ConnectComponent } from './mapper/MapperPerfil';
import { V4hSpin } from '../spin';
import { iStore, iUser } from '~/domain/interfaces/models';
import { GetOneUser } from '~/domain/usecases/users/remote';
import { AlertMessage } from '../messages/AlertMessage';
import ListProfessionalData from '../registerProfessional/ListProfessionalData';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface ownProps {
  data: iProfessions[];
  actionUpdate: Function;
  actionDelete: Function;
  actionCreate: Function;
  getOneUser: (params: GetOneUser.Params) => Promise<iUser>;
}
const ProfessionalData: React.FC<ownProps> = ({
  data,
  actionUpdate,
  actionDelete,
  actionCreate,
  getOneUser,
}) => {
  const salectLabel = translator('Profissão');
  const [isSaving, setIsSaving] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [stateLess, setStateless] = useState<iSpecialty[]>([]);
  const [profession, setProfession] = useState<iProfessions>(
    data[0] as iProfessions,
  );
  const [specialties, setSpecialty] = useState<iSpecialty[]>(
    profession.professions.specialties,
  );
  const [previousData, setPreviousData] = useState<Array<number>>([]);
  const [deleteIds, setDeleteIds] = useState<Array<number>>([]);
  const { user } = useSelector((store: iStore) => store.auth.info);
  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const { loading } = useSelector((store: iStore) => store.specialty);

  // TODO: isReady está atualizando em horários indevidos
  function handleChangeSpecialty(value: iSpecialty[]) {
    setSpecialty(value);
    setIsReady(!isReady);
  }

  function handleChangePreviousData(value: Array<number>) {
    setPreviousData(value);
  }

  function handleChangeDeleteData(value: Array<number>) {
    setDeleteIds(value);
  }

  function handleChangeIsSaving() {
    setIsSaving(!isSaving);
  }

  const saveData = async () => {
    let errorResponse = null;

    const formattedSpecialties = await Promise.all(
      specialties.map(item => {
        return {
          specialty: item.id,
          docProf: item.docProf,
          docProfIssued: item.docProfIssued,
          docProfUF: item.docProfUF,
        };
      }),
    );

    const filteredAdded = await Promise.all(
      formattedSpecialties.filter(
        specialty => !previousData.find(id => specialty.specialty === id),
      ),
    );

    if (filteredAdded.length) {
      await Promise.all(
        filteredAdded.map(item => {
          const splitDate = item.docProfIssued?.split('/');

          return actionCreate({
            professionalId: profession.id,
            specialtyId: item.specialty,
            specialty: {
              docProf: item.docProf,
              docProfUF: item.docProfUF,
              docProfIssued:
                splitDate && splitDate.length >= 2
                  ? `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
                  : item.docProfIssued,
            },
          }).catch((err: any) => {
            errorResponse = err;
          });
        }),
      );

      if (errorResponse) {
        // AlertMessage({
        //   message: intl.formatMessage({
        //     id: 'Não foi possível atualizar especialidades, tente novamente',
        //   }),
        //   type: 'danger',
        // });
        makeReduxActiveMessage().active({
          active: MessageOptions.userEditDataError,
          actionOk: () => {
            closeModal();
          },
          actionCancel: () => {
            closeModal();
          },
        });
      }
    }

    if (!errorResponse) {
      const filteredDelete = await Promise.all(
        deleteIds.filter(deleteId => previousData.find(id => deleteId === id)),
      );

      const difference = previousData.filter(
        x => !formattedSpecialties.find(y => y.specialty === x),
      );

      if (difference.length) {
        difference.forEach(async item => {
          await actionDelete({
            professionalId: profession.id,
            specialtyId: item,
          }).catch((err: any) => {
            errorResponse = err;
          });
        });
      }

      if (filteredDelete.length) {
        await Promise.all(
          filteredDelete.map(item => {
            return actionDelete({
              professionalId: profession.id,
              specialtyId: item,
            }).catch((err: any) => {
              errorResponse = err;
            });
          }),
        );
      }
    }

    if (!errorResponse) {
      const filteredEdit = await Promise.all(
        formattedSpecialties.filter(specialty =>
          previousData.find(id => specialty.specialty === id),
        ),
      );

      if (filteredEdit.length) {
        await Promise.all(
          filteredEdit.map(item => {
            const splitDate = item.docProfIssued?.split('/');

            return actionUpdate({
              idProfessional: profession.id,
              idSpecialty: item.specialty,
              specialties: {
                docProf: item.docProf,
                docProfUF: item.docProfUF,
                docProfIssued:
                  splitDate && splitDate.length >= 2
                    ? `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
                    : item.docProfIssued,
              },
            });
          }),
        );
      }
    }

    if (!errorResponse) {
      makeReduxSetShowModal().set({ specialty: false });

      AlertMessage({
        message: intl.formatMessage({
          id: 'Especialidades atualizadas com sucesso!',
        }),
        type: 'success',
      });
    } else {
      // AlertMessage({
      //   message: intl.formatMessage({
      //     id: 'Não foi possível atualizar especialidades, tente novamente.',
      //   }),
      //   type: 'danger',
      // });
      makeReduxActiveMessage().active({
        active: MessageOptions.userEditDataError,
        actionOk: () => {
          closeModal();
        },
        actionCancel: () => {
          closeModal();
        },
      });
    }

    setDeleteIds([]);
  };

  function handleChange(value: string) {
    const optionSelected = data.filter(
      item => item.professions.id.toString() === value,
    ) as unknown as iProfessions;
    setProfession(optionSelected);
  }

  function renderProfessions() {
    const profSelected = data.map(item => {
      return (
        <>
          <option value={item.professions.id}>{item.professions.name}</option>
        </>
      );
    });

    return profSelected;
  }

  // Esse useEffect é responsável por definir quais especialidades irão pro componente
  // filho, as do Params (organizador), ou as do User (próprio profissional)
  useEffect(() => {
    const loadData = async () => {
      const professionalUser =
        user &&
        (await getOneUser({ userId: user?.id })).professionals?.find(
          item => item.orgUnit.id === orgUnitId,
        );
      const professionalSelected =
        data[0] &&
        (await getOneUser({ userId: data[0]?.userId })).professionals?.find(
          item => item.orgUnit.id === orgUnitId,
        );

      // Especialidades do user proveniente dos Params
      // const specialtiesParams = specialties;
      const specialtiesParams = professionalSelected?.professions.specialties;

      // Especialidades do user proveniente do Redux
      const specialtiesRedux = professionalUser?.professions.specialties;

      // Verifica quais especialidades serão usadas
      const specialtiesData = specialtiesParams ?? specialtiesRedux;

      if (specialtiesData) {
        const updatedStateless = specialtiesData.filter(v => {
          delete v.name;

          if (v.docProfIssued) {
            const date = new Date(v.docProfIssued);
            v.docProfIssued = date.toLocaleDateString(getLocale());
          }

          return v;
        });

        setStateless(updatedStateless);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (
      specialties.length &&
      profession.professions.specialties.length &&
      !_.isEqual(specialties, profession.professions.specialties)
    )
      saveData();
  }, [isReady]);

  return (
    <Container>
      <Header>
        <Icon src={IconBag} color="#656A6E" height="24px" />
        {translator('Profissional')}
      </Header>
      <Body>
        <Title>{translator('Dados profissionais')}</Title>

        <div id="select">
          {/* <Select
            label={salectLabel}
            width="400px"
            onChange={e => handleChange(e.target.value)}
          >
            {renderProfessions()}
          </Select> */}
        </div>
        <ListProfessionalData
          professionID={profession.professions.id}
          state={{}}
          handleDisabled={setDisabledButton}
          specialtyProfile={stateLess}
          back={() => {
            console.log('back');
          }}
          next={() => {
            console.log('next');
          }}
          handlePreviousData={value => handleChangePreviousData(value)}
          handleDeleteData={value => handleChangeDeleteData(value)}
          handleSave={value => handleChangeSpecialty(value)}
          save={isSaving}
        />
        <div>
          {/* <Specialty
            data={profession}
            handlePreviousData={handleChangePreviousData}
            handleDeleteData={handleChangeDeleteData}
            handleChange={handleChangeSpecialty}
            save={isSaving}
          /> */}
        </div>
      </Body>
      <Footer>
        <Button
          id="action"
          rounded
          onClick={handleChangeIsSaving}
          disabled={loading || disabledButton}
        >
          {loading
            ? translator('Salvando...')
            : translator('Salvar alterações')}
        </Button>
      </Footer>
      {/* {loading && <V4hSpin fullScreen />} */}
    </Container>
  );
};

export default ConnectComponent(ProfessionalData);
