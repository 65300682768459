import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f4f7fe;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  /* justify-content: start; */
  flex-direction: column;
  /* padding-left: 20px;
  padding-right: 20px; */
  overflow: auto;

  div {
    /* margin-top: 5px; */
    display: flex;
    align-items: center;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;

  h1 {
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    text-align: center;
    padding-left: 5px;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;

    text-align: center;
    color: #7a7a7a;
  }
`;

export const MenuDiv = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 121px;
  background: ${props => props.theme.primary};
`;

export const Left = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 0 12px 8px 12px;

  h1 {
    color: #7a7a7a;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 15px;
`;

export const Content = styled.div`
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
`;

export const Signed = styled.div`
  gap: 10px;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 150%;

    color: #004ba5;
  }
`;

export const SendPatient = styled.div`
  padding-right: 10px;
  p {
    color: #7a7a7a;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const Pdf = styled.div`
  width: 100%;
  height: 140vh;
`;

export const Session = styled.div`
  display: flex;
  margin: 10px 0;
  justify-content: space-around;
`;

export const ChooseCertificate = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  #certificate {
    display: flex;
    justify-content: center;
    max-width: 600px;
    width: 100%;
  }
`;

export const TextAreaDoc = styled.div`
  width: 100%;
  height: 50vh;
  padding: 10px;
  border-radius: 2px;

  .observacoes {
    margin-top: 25px;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.160976px;

    color: #004ba5;
  }
`;
